<template>
  <div class="vehicleequipment">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="设备名称">
            <el-input
              v-model="form.deviceName"
              placeholder="请输入设备名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select v-model="form.deviceType" placeholder="请选择机构" clearable>
              <el-option
                v-for="item in equipmentList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备厂家">
            <el-input
              v-model="form.deviceFactory"
              placeholder="请输入设备厂家"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="设备编号">
            <el-input
              v-model="form.deviceSn"
              placeholder="请输入设备编号"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()"
              >搜索</el-button
            >
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="addDialogFormVisible = true"
              >添加</el-button
            >
            <el-button type="primary" icon="el-icon-delete" @click="reset">重置</el-button>

          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column
            prop="deviceName"
            label="设备名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="deviceFactory"
            label="设备厂家"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="deviceSn"
            label="设备编号"
            align="center"
          ></el-table-column>
          <el-table-column prop="deviceType" label="设备类型" align="center">
            <template slot-scope="scope">
              <span effect="dark" v-if="scope.row.deviceType === '0'">{{
                "车载终端"
              }}</span>
              <span effect="dark" v-else-if="scope.row.deviceType === '1'">{{
                "摄像头"
              }}</span>
              <span effect="dark" v-else-if="scope.row.deviceType === '2'">{{
                "平板"
              }}</span>
              <span effect="dark" v-else-if="scope.row.deviceType === '3'">{{
                "SIM卡"
              }}</span>
              <span effect="dark" v-else-if="scope.row.deviceType === '4'">{{
                "硬盘"
              }}</span>
              <span effect="dark" v-else-if="scope.row.deviceType === '5'">{{
                "GPS"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span effect="dark" type="warning" v-if="scope.row.status === '0'">{{
                "新增"
              }}</span>
              <span type="success" effect="dark" v-else-if="scope.row.status === '1'">{{
                "正常"
              }}</span>
              <span type="info" effect="dark" v-else>{{ "停用" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="150px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)"
                >查看详情</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                @click="edit(scope.row)"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10, 15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
        <span slot="title" class="dialog-Title">机构详情</span>
        <el-form
          :model="selectForm"
          inline
          ref="selectForm"
          :rules="addFormRules"
          class="selectForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="设备名称" prop="deviceName">
              <el-input
                v-model="selectForm.deviceName"
                autocomplete="off"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备厂家">
              <el-input
                v-model="selectForm.deviceFactory"
                autocomplete="off"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备编号" prop="deviceSn">
              <el-input
                v-model="selectForm.deviceSn"
                autocomplete="off"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="设备类型" prop="deviceType">
              <el-select
                v-model="selectForm.deviceType"
                placeholder="请选择设备类型"
                filterable
                clearable
                :disabled="isDisabled"
              >
                <el-option label="车载终端" value="0"></el-option>
                <el-option label="摄像头" value="1"></el-option>
                <el-option label="平板" value="2"></el-option>
                <el-option label="SIM卡" value="3"></el-option>
                <el-option label="硬盘" value="4"></el-option>
                <el-option label="GPS" value="5"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="selectForm.status"
                placeholder="请选择状态"
                filterable
                clearable
                :disabled="isDisabled"
              >
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="selectForm.remark"
                autocomplete="off"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog
        :visible.sync="addDialogFormVisible"
        class="add-dialog"
        @close="addDialogClose()"
      >
        <span slot="title" class="dialog-Title">添加车载设备</span>
        <el-form
          :model="addForm"
          inline
          ref="addForm"
          :rules="addFormRules"
          class="addForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="设备名称" prop="deviceName">
              <el-input v-model="addForm.deviceName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="设备厂家">
              <el-input v-model="addForm.deviceFactory" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="设备编号" prop="deviceSn">
              <el-input v-model="addForm.deviceSn" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="设备类型" prop="deviceType">
              <el-select
                v-model="addForm.deviceType"
                placeholder="请选择设备类型"
                filterable
                clearable
              >
                <el-option label="车载终端" value="0"></el-option>
                <el-option label="摄像头" value="1"></el-option>
                <el-option label="平板" value="2"></el-option>
                <el-option label="SIM卡" value="3"></el-option>
                <el-option label="硬盘" value="4"></el-option>
                <el-option label="GPS" value="5"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="addForm.status"
                placeholder="请选择状态"
                filterable
                clearable
              >
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'vehicleequipment',
  components: {},
  data () {
    return {
      tabData: [], // 表格数据源
      form: { deviceName: '', deviceType: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {
        status: '0'
      }, // 添加表单
      // is: true,
      addFormRules: {
        deviceName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        deviceSn: [{ required: true, message: '请输入设备编号', trigger: 'blur' }],
        deviceType: [{ required: true, message: '请选择设备类型', trigger: 'blur' }],
        status: [{ required: true, message: '请选择设备状态', trigger: 'blur' }]
      }, // 添加表单校验规则
      selectFormRules: {},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [
        { label: '新增', status: '0' },
        { label: '正常', status: '1' },
        { label: '停用', status: '2' }
      ],
      equipmentList: [
        {
          name: '车载终端',
          value: 0
        }, {
          name: '摄像头',
          value: 1
        }, {
          name: '平板',
          value: 2
        }, {
          name: 'SIM卡',
          value: 3
        }, {
          name: '硬盘',
          value: 4
        }, {
          name: 'GPS',
          value: 5
        }
      ]
    }
  },
  created () {
    // deviceSn
    if (this.$route.params.deviceSn) {
      this.form.deviceSn = this.$route.params.deviceSn
    }
    this.getAllCar()
    console.log(this.$route.params)
  },
  methods: {

    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取所有车载设备信息管理
    async getAllCar () {
      const { data: res } = await this.$http.post(
        // '/vehicleServer/tInfoDevice/selecteTInfoDeviceService',
        '/pmService/tInfoDevice/selectDevicePage',
        {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          ...this.form
          // "status": 1
        }
      )
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取车载设备信息失败')
      this.tabData = res.data.records
      this.total = res.data.total
    },
    // 点击搜索按钮触发
    async search () {
      console.log(this.form, 'this.form')
      // 判断有输入账号
      if (this.form.deviceName === '' && this.form.deviceType == '') {
        this.getAllCar()
      } else {
        // 调接口，进行查询
        this.paging.PageNo = 1
        const { data: res } = await this.$http.post(
        //   '/pmService/tInfoDevice/selectDevicePage',
          '/pmService/tInfoDevice/selectDevicePage',

          {
            pageSize: this.paging.pageSize,
            pageNo: this.paging.PageNo,
            deviceName: this.form.deviceName,
            deviceType: this.form.deviceType,
            deviceFactory: this.form.deviceFactory,
            deviceSn: this.form.deviceSn
          }
        )
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data.records
        this.total = res.data.total
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 编辑客户--确定按钮
    editClient () {
      // 预校验
      this.$refs.editForm.validate(async (valid) => {
        if (!valid) return
        // 调接口，发送编辑客户信息请求
        const { data } = await this.$http.post(
          'vehicleServer/tInfoDevice/updateTInfoDeviceService',
          this.editForm
        )
        console.log(data)
        this.editDialogFormVisible = false
        this.select()
        // 判断请求是否成功
        // if (data.sattus !== '200') return this.$message.error('添加客户失败')
        // 重新渲染数据
        // this.getAllClient()
        // 关闭弹框
      })
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.deviceName}`, '删除车载设备信息', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      })
        .then(async () => {
          try {
            const { data: res } = await this.$http.post(
              '/vehicleServer/tInfoDevice/deleteTInfoDeviceService',
              JSON.parse(JSON.stringify(e))
            )
            // 判断是否删除成功
            if (res.statusCode !== '200') { return this.$message.error('删除车载设备信息失败') }
            // 成功提示
            this.$message.success('删除车载设备信息成功')
            // 刷新视图
            this.getAllCar()
          } catch (e) {
            console.log(e)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 点击详情确定框触发
    async sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) {
        return (this.orderDialogFormVisible = false)
      } else {
        // 进行编辑操作
        const { data: res } = await this.$http.post(
          '/pmService/tInfoDevice/updateTInfoDeviceService',
          JSON.parse(JSON.stringify(this.selectForm))
        )
        console.log(res)
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('修改车载设备信息失败')
        // 成功提示
        this.$message.success('修改车载设备信息成功')
        // 重新渲染数据
        this.getAllCar()
        this.orderDialogFormVisible = false
      }
    },
    // 添加机构--确定按钮
    addSure () {
      // console.log(JSON.parse(JSON.stringify(this.addForm)));
      // 预校验
      this.$refs.addForm.validate(async (valid) => {
        if (!valid) return
        this.addForm.transportTypeId = +this.addForm.transportTypeId
        this.addForm.companyId = +this.addForm.companyId
        // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post(
          '/vehicleServer/tInfoDevice/insertTInfoDeviceService',
          JSON.parse(JSON.stringify(this.addForm))
        )
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
      this.addForm = {}
    },
    reset () { // 重置
      for (const key in this.form) {
        this.form[key] = ''
      }
      this.paging.pageSize = 10
      this.paging.PageNo = 1
      this.search('重置成功')
    }
  }
}
</script>
<style lang="less" scoped>
.vehicleequipment {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
